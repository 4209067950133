/** @jsx jsx */
import { jsx } from "@emotion/react"
import SEO from "@src/components/seo"
import { MagazineBase } from "@src/pages-base/MagazineBase"
import { graphql } from "gatsby"
import { Fragment } from "react"

export default ({ data }: { data: any }) => (
  <Fragment>
    <SEO title="Verdieping" />
    <MagazineBase locale="nl" data={data} />
  </Fragment>
)

export const query = graphql`
  query magazineQuery {
    allDatoCmsMagazineItem(
      filter: { locale: { eq: "nl" }, hidden: { ne: true } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      edges {
        node {
          title
          id
          slug
          tags
          tagsAuthor
          meta {
            firstPublishedAt
          }
          thumbnail {
            fluid(maxWidth: 700, imgixParams: { fm: "jpg", auto: "compress" }) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    datoCmsSiteConfig {
      highlightedMagazineItem {
        slug
        title
        id
        tags
        tagsAuthor
        meta {
          firstPublishedAt
        }
        thumbnail {
          fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`
